<template>
    <div class="common-select">
        <div class="select-content" @click="open = !open">
            <div class="label">{{value}}</div><i class="el-icon-caret-bottom"></i>
        </div>
        <div class="select-options" :class="{open: open}">
            <div class="select-item" v-for="(item, index) in options" :key="index" @click="onChange(item)">{{item.name}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommonSelect',
    props: {
        value: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            open: false
        }
    },
    watch: {
        value(v) {
            console.log(v, 'tochain-watch')
            this.open = false
        }
    },
    methods: {
        onChange(item) {
            this.$emit('change', item)
        }
    }
}
</script>

<style lang="less">
.common-select {
    position: relative;
    width: 100%;
    .select-content {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #fff;
        border-radius: 9px;
        padding: 4px 12px;
        .label {
            height: 24px;
            line-height: 24px;
            padding-right: 8px;
        }
        i {
            font-size: 20px;
        }
    }
    .select-options {
        position: absolute;
        display: none;
        background: #fff;
        color: #000;
        padding: 12px 0;
        border-radius: 6px;
        &.open {
            display: block;
        }
        .select-item {
            padding: 6px 16px;
            color: #444;
            &:hover {
                color: #000;
            }
            &:active {
                background: #ccc;
            }
        }
    }
}
</style>
